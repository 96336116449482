<template>
  <div
    class="flex flex-col justify-between bg-white border border-solid h-full overflow-hidden"
    :class="
      isWhiteLabel
        ? 'card-shadow rounded'
        : 'shadow-lg rounded-lg rounded-t-none'
    "
  >
    <div
      v-if="!!title || !!$slots.title"
      class="flex items-center border-b justify-evenly"
    >
      <div class="w-12" />
      <div
        v-if="title"
        class="w-full text-center text-lg p-4"
        :class="{ 'text-xl font-bold color-main-text': isWhiteLabel }"
      >
        {{ title }}
      </div>
      <slot v-else-if="$slots.title" name="title" />

      <!-- Refetch spinner button -->
      <div class="flex items-center w-12 border-none">
        <button
          v-if="canRefetch || isSpinning"
          class="p-1 outline-none focus:shadow-outline border-none"
          :class="{ 'pointer-events-none': isSpinning }"
          @click="handleSpinnerClick"
        >
          <el-icon
            class="th-refresh text-xl text-th-primary-blue"
            :class="{ 'th-spin shadow-2xl': isSpinning }"
            ><Refresh
          /></el-icon>
        </button>
      </div>
    </div>
    <div v-loading="loading" class="flex-1 overflow-hidden relative">
      <no-data
        v-if="!hasData"
        :icon="noDataProps.icon"
        :size="iconSize"
        :text="noDataProps.text"
        :color="noDataProps.color"
      />
      <slot />
    </div>
  </div>
</template>

<script>
import NoData from './NoData'
import { isUnifiedCommerce } from '@/constants'

export default {
  components: {
    NoData
  },
  props: {
    title: {
      type: String,
      required: false,
      default: undefined
    },
    hasData: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    noDataIcon: {
      type: String,
      default: ''
    },
    noDataColor: {
      type: String,
      default: undefined
    },
    iconSize: {
      type: String,
      default: undefined
    },
    canRefetch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isSpinning: false
    }
  },
  computed: {
    noDataProps() {
      const color = this.canRefetch ? 'var(--error-color-light)' : undefined

      const text = this.canRefetch
        ? this.$t('pages.home.widgets.timeout_error')
        : undefined

      const icon = this.canRefetch ? 'th-icon-warning-outline' : this.noDataIcon

      return { color, text, icon }
    },
    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },
  watch: {
    canRefetch(newValue) {
      if (newValue) {
        setTimeout(() => {
          // timeout makes smoother stopping of the spinner.
          this.isSpinning = false
        }, 200)
      }
    }
  },
  methods: {
    handleSpinnerClick() {
      this.isSpinning = true
      this.$emit('refetch')
      setTimeout(() => {
        // let the spinner spin for a bit while making the request before removing it.
        this.isSpinning = false
      }, 2000)
    }
  }
}
</script>

<style scoped>
.th-spin {
  animation: spin 1s reverse linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
