<template>
  <div class="absolute w-full h-full flex justify-center items-center">
    <el-card shadow="never" class="border-0 bg-transparent">
      <svgicon
        class="block m-auto"
        :src="require(`@/assets/icons/${icon}.svg`)"
        :height="iconSize"
        :width="iconSize"
        :fill="color"
      />
      <div class="mt-10" :style="`color: ${color}`">
        {{ noDataText }}
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'NoData',
  props: {
    icon: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'small'
    },
    color: {
      type: String,
      default: 'var(--primary-color-focus)'
    },
    text: {
      type: String,
      default: undefined
    }
  },
  computed: {
    iconSize() {
      return { small: '40px', large: '90px' }[this.size]
    },
    noDataText() {
      return this.text || this.$t('pages.home.widgets.no_data_available')
    }
  }
}
</script>
